<!--
 * @Description: 批量修改设置基础价格
 * @Author: 琢磨先生
 * @Date: 2022-08-19 22:02:53
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 09:37:40
-->
<template>
  <el-button
    style="margin-left:15px;"
    type="danger"
    icon="EditPen"
    size="small"
    round v-power="'seller/v1/housePrice/batch/updateBasePrice'"
    @click="visible = true"
  >批量修改平日价</el-button>
  <el-drawer
    title="批量修改平日价"
    v-model="visible"
    direction="rtl"
    size="1000px"
    :destroy-on-close="true"
    :show-close="true"
    custom-class="drawer_house_batch_shop"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="140px"
      label-position="top"
      :inline="false"
    >
      <div>
        <el-alert
          type="info"
          :closable="false"
          :title="`已选 ${selectionList.length} 个房源`"
          style="margin-bottom:10px;"
        >
          <el-tag
            :closable="true"
            v-for="(item, i) in selectionList"
            :key="item.id"
            style="margin-right: 20px; margin-bottom: 10px"
            @close="removeSelectionItem(item,i)"
          >
            <b>{{item.id}}</b>
            、{{ item.private_name
            }}
          </el-tag>
        </el-alert>
        <el-popover :visible="visiblePopover" :width="800" placement="bottom-start" trigger="click">
          <template #reference>
            <el-button type="primary" link icon="plus" @click="visiblePopover=!visiblePopover">添加房源</el-button>
          </template>
          <el-form :model="query" ref="form" :inline="true">
            <!-- <el-form-item label="门店">
              <el-select v-model="query.shop_id" placeholder clearable style="width:200px;">
                <el-option :label="item.name" :value="item.id" :key="item.id" v-for="item in shops"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label>
              <el-input v-model="query.q" placeholder="房源、内部名称" style="width:240px;"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
              <el-button round @click="visiblePopover=false" icon="close">关闭</el-button>
            </el-form-item>
          </el-form>

          <el-table
            ref="shopHouseTable"
            class="shop_house_table"
            :data="tableData.data"
            @selection-change="selectionChange"
            height="450px"
            row-key="id"
          >
            <el-table-column type="selection" width="50" reserve-selection></el-table-column>
            <el-table-column label="编号" prop="id" width="80"></el-table-column>
            <el-table-column prop="private_name" label="内部名称" width="160"></el-table-column>
            <el-table-column label="房源标题" prop="name"></el-table-column>
          </el-table>
          <el-pagination
            v-if="tableData.counts > 0"
            @size-change="pageSizeChange"
            @current-change="pageCurrentChange"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :page-sizes="[20, 40]"
            layout="total,prev, pager, next"
            :total="tableData.counts"
          ></el-pagination>
        </el-popover>
        <el-button type="info" link @click="clearSelection">清除全部</el-button>
      </div>

      <el-form-item label="平日价" style="margin-top:20px;" prop="decimal_normal_price">
        <el-col :span="10">
          <el-input v-model="form.decimal_normal_price" placeholder="请输入平日价"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="周末价" prop="decimal_weekend_price">
        <el-col :span="10">
          <el-input v-model="form.decimal_weekend_price" placeholder="请输入平日价"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="节假日价" prop="decimal_holiday_price">
        <el-col :span="10">
          <el-input v-model="form.decimal_holiday_price" placeholder="请输入平日价"></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" :loading="saving" @click="onSubmit">确定</el-button>
    </template>
  </el-drawer>
</template>

<script>
// import shop_api from "@/http/shop_api.js";
export default {
  data() {
    return {
      visible: false,
      saving: false,
      loading: false,
      visiblePopover: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      //房源列表
      tableData: {
        counts: 0,
      },
      //已选择的房源列表
      selectionList: [],
      form: {},
      rules: {
        decimal_normal_price: [{ required: true, message: "必填", trigger: "blur" }],
        decimal_weekend_price: [{ required: true, message: "必填", trigger: "blur" }],
        decimal_holiday_price: [{ required: true, message: "必填", trigger: "blur" }],
      },
      //所有的门店数据
      shop_list: [],
    };
  },
  props: ["shops"],
  emits: ["close"],
  created() {
    this.loadData();
    // shop_api.get_shops().then((res) => {
    //   if (res.code == 0) {
    //     this.shop_list = res.data;
    //   }
    // });
  },
  watch: {},
  methods: {
    /**
     * 搜索数据
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("seller/v1/house", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 表格选择发生变化
     */
    selectionChange(nodes) {
      this.selectionList = nodes;
    },
    /**
     * 移除已经选择的项
     * @param {*} item
     * @param {*} i
     */
    removeSelectionItem(item, i) {
      this.selectionList.splice(i, 1);
      this.$refs.shopHouseTable.toggleRowSelection(item, false);
    },
    /**
     * 清除全部选择
     */
    clearSelection() {
      this.selectionList = [];
      this.$refs.shopHouseTable.clearSelection();
    },
    /**
     * 提交保存
     */
    onSubmit() {
      if (this.selectionList.length == 0) {
        this.$message.error("请选择房源");
        return;
      }
      this.form.house_ids = this.selectionList.map((x) => x.id);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("seller/v1/housePrice/batch/updateBasePrice", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.clearSelection();
                this.$emit('close')
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style >
.shop_house_table.el-table .cell {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>